import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import toDecimal from '../../lib/toDecimal';
import Title from './Title';

const toDate = (date6) => {
    const y = date6.substr(0, 4);
    const m = date6.substr(4, 2);
    const d = date6.substr(6, 2);
    return new Date(`${y}-${m}-${d}`);
};

const prepData = (data, dataKey) => {
    return data.map((item, index) => {
        const day = toDate(item.day).toLocaleDateString('en-US', {timeZone: 'utc', month: 'numeric', day: 'numeric'});
        const position = toDecimal(item[dataKey], 1);
        return { position, day };
    });
};

export default function PositionTrendMetric(props) {
    const { title, data, dataKey } = props;
    const preppedData = prepData(data, dataKey);
    const theme = useTheme();

    return (
        <React.Fragment>
            <Title>{title}</Title>
            <ResponsiveContainer>
                <LineChart
                    data={preppedData}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis dataKey="day" stroke={theme.palette.text.secondary} />
                    <YAxis reversed allowDecimals={false} hide stroke={theme.palette.text.secondary} interval="preserveStartEnd" domain={['dataMin', 'dataMax']}>
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
                        >
                            Avg. Pos.
                        </Label>
                    </YAxis>
                    <Line type="monotone" dataKey="position" stroke={theme.palette.primary.main} dot={true} label={{fontSize: 11, position: 'top'}} />
                </LineChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
