import { useState } from 'react';

export default function createFilter(title, options, defaultValue = '') {
    return {
        title,
        slug: title.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-'),
        default: defaultValue,
        options,
        state: useState(defaultValue),
    }
}
