import ReactDOM from 'react-dom';
import React from "react";
import App from "./components/App";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

if (document.getElementById('app')) {
    ReactDOM.render(
        <Router history={history}>
            <App />
        </Router>,
        document.getElementById('app')
    );
}
