import React from 'react';
import Title from './Title';
import { BarChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Bar, Tooltip } from "recharts";

export default function BrandRanking(props) {
    const { title, description, data, metricKey } = props;

    return (
        <React.Fragment>
            <Title>{title}</Title>
            <p>{description}</p>
            <ResponsiveContainer width="100%" height={800}>
                <BarChart
                    data={data}
                    layout="vertical"
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" width={150} />
                    <Tooltip />
                    <Bar dataKey={metricKey} fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
