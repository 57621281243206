import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import toDecimal from '../../lib/toDecimal';
import Title from './Title';

const useStyles = makeStyles({
    depositContext: {
        flex: 1,
    },
});

function Trend(props) {
    if (props.direction < 0) return <TrendingDownIcon color="error" />;
    if (props.direction > 0) return <TrendingUpIcon color="action" />;
    return null;
}

export default function PositionMetric(props) {
    const { title, current, previous } = props;
    const currText = current === 0 ? 'n/a' : toDecimal(current, 1);
    const prevText = previous === 0 ? 'No data for previous period' : toDecimal(previous, 1);
    const classes = useStyles();
    const trendDiff = previous - current;

    return (
        <React.Fragment>
            <Title>{title}</Title>
            <Typography component="p" variant="h4">
                {currText} <Trend direction={trendDiff} />
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                {prevText}
            </Typography>
        </React.Fragment>
    );
}
