import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import createFilter from '../../lib/createFilter';
import Main from '../Main';
import BrandRanking from '../Metrics/BrandRanking';
import toDecimal from "../../lib/toDecimal";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

const sortFunctions = {
    brand: (a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
    rating: (a, b) => a.avgRating > b.avgRating ? -1 : 1,
    reviews: (a, b) => a.totalReviews > b.totalReviews ? -1 : 1,
    velocity: (a, b) => a.reviewsPerDay > b.reviewsPerDay ? -1 : 1,
};

function normalizeData(d) {
    return d.map((brand) => {
        return {
            name: brand.brand.shortname,
            rating: brand.current.avgRating,
            reviews: brand.current.totalReviews,
            reviewsPerDay: brand.reviewsPerDay,
        };
    });
}

export default function ReputationRankings() {
    const classes = useStyles();
    const [state, setState] = useState([]);

    const filters = {
        range: createFilter('Date Range', {
            '7d': 'Last 7 days',
            '14d': 'Last 14 days',
            '30d': 'Last 30 days'
        }, '7d'),
        market: createFilter('Market', {
            '': 'All',
            COL: 'Columbus',
            DAY: 'Dayton',
            CIN: 'Cincinnati'
        }, ''),
    };
    const sort = createFilter('Sort',{
        brand: 'Brand Name',
        rating: 'Rating',
        reviews: 'Reviews',
        velocity: 'Review Velocity',
    }, 'brand');

    useEffect(() => {
        axios.get(`/api/reputation?range=${filters.range.state[0]}&market=${filters.market.state[0]}`).then(res => setState(res.data));
    }, [filters.range.state[0], filters.market.state[0]]);

    console.log({state})
    const rankingData = normalizeData(state);
    let sortedRankingData = rankingData;
    if (sortFunctions.hasOwnProperty(sort.state[0])) {
        sortedRankingData = rankingData.sort(sortFunctions[sort.state[0]]).slice();
    }
    console.log({sortedRankingData})

    return (
        <Main filters={filters} sort={sort}>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={6}>
                        <Paper className={classes.paper}>
                            <BrandRanking
                                title="Review Velocity"
                                description="Reviews per day over the given period"
                                data={sortedRankingData}
                                metricKey="reviewsPerDay"
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Main>
    );
}
