import React from 'react';
import MainExample from './components/MainExample';
import MainDashboard from './components/MainDashboard';
import Positions from './components/Main/Positions';
import Visibility from './components/Main/Visibility';
import Reputation from './components/Main/Reputation';
import BrandRankings from './components/Main/BrandRankings';
import ReputationRankings from './components/Main/ReputationRankings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';

export default [
    {
        path: "/",
        exact: true,
        title: 'Dashboard',
        icon: <DashboardIcon />,
        main: <MainDashboard />,
    },
    // {
    //     path: "/example",
    //     title: 'Example',
    //     icon: <BarChartIcon />,
    //     main: <MainExample />,
    // },
    {
        path: "/brands",
        title: 'Brand Rankings',
        icon: <BarChartIcon />,
        main: <BrandRankings />,
    },
    {
        path: "/positions",
        title: 'Search Position',
        icon: <BarChartIcon />,
        main: <Positions />,
    },
    {
        path: "/visibility",
        title: 'Search Visibility',
        icon: <BarChartIcon />,
        main: <Visibility />,
    },
    {
        path: "/reputation",
        title: 'Reputation',
        icon: <BarChartIcon />,
        main: <Reputation />,
    },
    {
        path: "/reputation-rankings",
        title: 'Reputation Rankings',
        icon: <BarChartIcon />,
        main: <ReputationRankings />,
    },
];
