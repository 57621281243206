import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import createFilter from '../../lib/createFilter';
import Main from '../Main';
import NumberMetric from '../Metrics/Number';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 200,
    },
}));

const sortFunctions = {
    brand: (a, b) => a.brand.shortname.toLowerCase() > b.brand.shortname.toLowerCase() ? 1 : -1,
    rating: (a, b) => a.current.avgRating > b.current.avgRating ? -1 : 1,
    reviews: (a, b) => a.current.totalReviews > b.current.totalReviews ? -1 : 1,
    velocity: (a, b) => a.reviewsPerDay > b.reviewsPerDay ? -1 : 1,
};

export default function Reputation() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [state, setState] = useState([]);

    const filters = {
        range: createFilter('Date Range', {
            '7d': 'Last 7 days',
            '14d': 'Last 14 days',
            '30d': 'Last 30 days'
        }, '7d'),
        market: createFilter('Market', {
            '': 'All',
            COL: 'Columbus',
            DAY: 'Dayton',
            CIN: 'Cincinnati'
        }, ''),
    };
    const sort = createFilter('Sort',{
        brand: 'Brand Name',
        rating: 'Rating',
        reviews: 'Reviews',
        velocity: 'Review Velocity',
    }, 'brand');

    useEffect(() => {
        axios.get(`/api/reputation?range=${filters.range.state[0]}&market=${filters.market.state[0]}`).then(res => setState(res.data));
    }, [filters.range.state[0], filters.market.state[0]]);

    let sortedState = state;
    if (sortFunctions.hasOwnProperty(sort.state[0])) {
        sortedState = state.sort(sortFunctions[sort.state[0]]).slice();
    }

    return (
        <Main filters={filters} sort={sort}>
            {sortedState.map((brand, index) => (
                <Container maxWidth="lg" className={classes.container} key={index}>
                    <h3>{brand.brand.shortname}</h3>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <NumberMetric
                                    title="Avg. Rating"
                                    current={brand.current.avgRating}
                                    previous={brand.previous.avgRating}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <NumberMetric
                                    title="Total Reviews"
                                    current={brand.current.totalReviews}
                                    previous={brand.previous.totalReviews}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <NumberMetric
                                    title="Review Velocity"
                                    description="Reviews per day over period"
                                    current={brand.reviewsPerDay}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            ))}
        </Main>
    );
}
