import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import createFilter from '../../lib/createFilter';
import Main from '../Main';
import BrandRanking from '../Metrics/BrandRanking';
import toDecimal from "../../lib/toDecimal";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

const sortFunctions = {
    brand: (a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
    visibility: (a, b) => a.visibility > b.visibility ? -1 : 1,
    position: (a, b) => a.avgPos > b.avgPos ? 1 : -1,
};

function normalizeData(d) {
    return d.map((brand) => {
        return {
            name: brand.brand.shortname,
            avgPos: toDecimal(brand.current.avgPos, 1),
            visibility: toDecimal(brand.current.visibility, 1),
        };
    });
}

export default function BrandRankings() {
    const classes = useStyles();
    const [state, setState] = useState([]);

    const filters = {
        range: createFilter('Date Range', {
            '7d': 'Last 7 days',
            '14d': 'Last 14 days',
            '30d': 'Last 30 days'
        }, '7d'),
        tag: createFilter('Keyword Group', {
            '': 'All',
            volume: 'High Volume',
            standalone: 'Standalone',
            local: 'Local',
            city: 'City',
            favorite: 'Favorites'
        }, 'favorite'),
        posType: createFilter('Position Type', {
            '': '3pack & Links',
            '1': '3pack Only',
            '2': 'Links only',
        }, ''),
        market: createFilter('Market', {
            '': 'All',
            COL: 'Columbus',
            DAY: 'Dayton',
            CIN: 'Cincinnati'
        }, ''),
    };
    const sort = createFilter('Sort',{
        brand: 'Brand Name',
        position: 'Position',
        visibility: 'Visibility',
    }, 'brand');

    useEffect(() => {
        axios.get(`/api/positions?range=${filters.range.state[0]}&tag=${filters.tag.state[0]}&posType=${filters.posType.state[0]}&market=${filters.market.state[0]}`).then(res => setState(res.data));
    }, [filters.range.state[0], filters.tag.state[0], filters.market.state[0], filters.posType.state[0]]);

    console.log({state})
    const rankingData = normalizeData(state);
    let sortedRankingData = rankingData;
    if (sortFunctions.hasOwnProperty(sort.state[0])) {
        sortedRankingData = rankingData.sort(sortFunctions[sort.state[0]]).slice();
    }

    return (
        <Main filters={filters} sort={sort}>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={6}>
                        <Paper className={classes.paper}>
                            <BrandRanking
                                title="Avg. Position"
                                description="Average ranking position. Lower is better."
                                data={sortedRankingData}
                                metricKey="avgPos"
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <Paper className={classes.paper}>
                            <BrandRanking
                                title="Visibility"
                                description="Calculated based on keyword position. 100% visibility means all keywords in first spot."
                                data={sortedRankingData}
                                metricKey="visibility"
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Main>
    );
}
