import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Title from './Title';

const useStyles = makeStyles({
    depositContext: {
        flex: 1,
    },
});

function Trend(props) {
    if (props.direction < 0) return <TrendingDownIcon color="error" />;
    if (props.direction > 0) return <TrendingUpIcon color="action" />;
    return null;
}

export default function NumberMetric(props) {
    const { title, description, current, previous, prefix = '', suffix = '', zeroLabel = '0' } = props;
    const currText = current === 0 ? zeroLabel : `${prefix}${current}${suffix}`;
    const prevText = !previous ? 'No data for previous period' : `${prefix}${previous}${suffix}`;
    const classes = useStyles();
    const trendDiff = current - previous;

    return (
        <React.Fragment>
            <Title>{title}</Title>
            <p>{description}</p>
            <Typography component="p" variant="h4">
                {currText} <Trend direction={trendDiff} />
            </Typography>
            {previous ? (
                <Typography color="textSecondary" className={classes.depositContext}>
                    {prevText}
                </Typography>
            ) : null}
        </React.Fragment>
    );
}
