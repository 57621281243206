import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        paddingRight: theme.spacing(4),
        minWidth: '12em',
    },
}));

function Field(props) {
    const classes = useStyles();
    const [ val, setVal ] = props.filter.state;
    const { title, slug, options } = props. filter;
    return (
        <FormControl className={classes.formControl}>
            <InputLabel id={`${slug}-label`}>{title}</InputLabel>
            <Select
                labelId={`${slug}-label`}
                id={slug}
                value={val}
                onChange={(e) => setVal(e.target.value)}
            >
                {Object.entries(options).map(([k, v], index) => (
                    <MenuItem value={k} key={index}>{v}</MenuItem>
                 ))}
            </Select>
        </FormControl>
    );
}

export default function Filters(props) {
    return (
        <>
            {Object.entries(props.filters).map(([k, v], index) => (
                <Field filter={v} key={index} />
            ))}
            {props.sort ? <Field filter={props.sort} /> : null}
        </>
    );
}
