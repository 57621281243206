import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import createFilter from '../../lib/createFilter';
import Main from '../Main';
import Position from '../Metrics/Position';
import PositionTrend from '../Metrics/PositionTrend';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 200,
    },
}));

const sortFunctions = {
    brand: (a, b) => a.brand.shortname.toLowerCase() > b.brand.shortname.toLowerCase() ? 1 : -1,
    visibility: (a, b) => a.current.visibility > b.current.visibility ? 1 : -1,
    position: (a, b) => a.current.avgPos > b.current.avgPos ? 1 : -1,
};

export default function Positions() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [state, setState] = useState([]);

    const filters = {
        range: createFilter('Date Range', {
            '7d': 'Last 7 days',
            '14d': 'Last 14 days',
            '30d': 'Last 30 days'
        }, '7d'),
        tag: createFilter('Keyword Group', {
            '': 'All',
            volume: 'High Volume',
            standalone: 'Standalone',
            local: 'Local',
            city: 'City',
            favorite: 'Favorites'
        }, 'favorite'),
        posType: createFilter('Position Type', {
            '': '3pack & Links',
            '1': '3pack Only',
            '2': 'Links only',
        }, ''),
        market: createFilter('Market', {
            '': 'All',
            COL: 'Columbus',
            DAY: 'Dayton',
            CIN: 'Cincinnati'
        }, ''),
    };
    const sort = createFilter('Sort',{
        brand: 'Brand Name',
        position: 'Position'
    }, 'brand');

    useEffect(() => {
        axios.get(`/api/positions?range=${filters.range.state[0]}&tag=${filters.tag.state[0]}&posType=${filters.posType.state[0]}&market=${filters.market.state[0]}`).then(res => setState(res.data));
    }, [filters.range.state[0], filters.tag.state[0], filters.market.state[0], filters.posType.state[0]]);

    let sortedState = state;
    if (sortFunctions.hasOwnProperty(sort.state[0])) {
        sortedState = state.sort(sortFunctions[sort.state[0]]).slice();
    }

    return (
        <Main filters={filters} sort={sort}>
            {sortedState.map((brand, index) => (
                <Container maxWidth="lg" className={classes.container} key={index}>
                    <h3>{brand.brand.shortname} <small className="text-sm">(<a href={`https://www.semrush.com/tracking/overview/${brand.brand.semrush_pid}.html?report_mode_chart=avgPosition`} target="_blank">SEMRush</a>)</small></h3>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <Position
                                    title="Avg. Position"
                                    current={brand.current.avgPos}
                                    previous={brand.previous.avgPos}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={8} lg={9}>
                            <Paper className={fixedHeightPaper}>
                                <PositionTrend
                                    title="Position Trend"
                                    data={brand.current.days}
                                    dataKey="avgPos"
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            ))}
        </Main>
    );
}
